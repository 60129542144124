import React from "react"
import trattamentoStyles from "../../styles/home/trattamento.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import {formatPrice} from "../../utilities/formatPrice"

const Trattamento= ({cssClass}) => {
    const data = useStaticQuery(graphql`
    query TrattamentoVetrinaQuery {
      strapiVetrinaTrattamenti {
        titolo
        trattamento {
            categoria
            nome
            prezzo
            slug
            foto {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            durata
          }
      }
    }
`)

  return (
    <div className={cssClass}>
          { data.strapiVetrinaTrattamenti.titolo && <p className={trattamentoStyles.titolo}>{data.strapiVetrinaTrattamenti.titolo}</p>}
            {data.strapiVetrinaTrattamenti.trattamento && <div className={trattamentoStyles.cardBorderWrap}>
    <div className={trattamentoStyles.card}>
      <Img
        className={trattamentoStyles.image}
        fluid={data.strapiVetrinaTrattamenti.trattamento.foto.childImageSharp.fluid}
        alt={`foto natura - ${data.strapiVetrinaTrattamenti.trattamento.foto.nome}`}
      />
      <h4 className={trattamentoStyles.title}>{data.strapiVetrinaTrattamenti.trattamento.nome}</h4>
      <span className={trattamentoStyles.category}>{data.strapiVetrinaTrattamenti.trattamento.categoria.replace(/_/g, ' ')}</span>
      <div className={trattamentoStyles.header}>
        <span className={trattamentoStyles.price}>{formatPrice(data.strapiVetrinaTrattamenti.trattamento.prezzo)}</span>
        <span className={trattamentoStyles.duration}>{data.strapiVetrinaTrattamenti.trattamento.durata}</span>
      </div>
      <div className={trattamentoStyles.headerBtn}>
      <Link className={trattamentoStyles.details} to={`/trattamenti/${data.strapiVetrinaTrattamenti.trattamento.slug}`}> dettagli </Link>
      </div>
      </div>
    </div>}
    </div>

  )
}

export default Trattamento