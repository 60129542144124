/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React from "react"
import Video from "../common/video"
import { useStaticQuery, graphql } from "gatsby"
import movieStyles from "../../styles/home/movie.module.scss"


const Movie = () => {
    const data = useStaticQuery(graphql`
    query MyMovie {
        strapiCalendarioAvvento(data: {eq: "video"}) {
          id
          foto {
            publicURL
          }
        }
      }
      `)

   const movieURL = data.strapiCalendarioAvvento.foto.publicURL

  return (
    <div className={movieStyles.wrapper}>
     <Video url={movieURL} />
    </div>
  )
}






export default Movie
