import React from "react"
import aforismaStyles from "../../styles/home/aforisma.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

const Aforisma = ({cssClass}) => {
    const data = useStaticQuery(graphql`
    query AforismaQuery {
      strapiVetrinaAforismi {
        titolo
        aforisma {
            foto {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
      }
    }
`)

  return (
    <div className={cssClass}>
          { data.strapiVetrinaAforismi.titolo && <p className={aforismaStyles.titolo}>{data.strapiVetrinaAforismi.titolo}</p>}
            {data.strapiVetrinaAforismi.aforisma.foto &&  <Link to="/aforismi"><Img
                className={aforismaStyles.foto}
                fluid={data.strapiVetrinaAforismi.aforisma.foto.childImageSharp.fluid}
                alt={data.strapiVetrinaAforismi.aforisma.foto.name}
            /> </Link> }
    </div>

  )
}

export default Aforisma