import React from "react"
import Layout from "../components/common/layout"
import homeStyles from "../styles/pages/home.module.scss"
import Banner from "../components/common/banner"
import Hero from "../components/home/hero"
import Bio from "../components/home/bio"
import Aforisma from "../components/home/aforisma"
import BlogVetrina from "../components/home/blog"
import Trattamento from "../components/home/trattamento"
import Corso from "../components/home/corso"
import Promozioni from "../components/home/promozioni"
import Calendario from "../components/calendario/calendario"
import Movie from "../components/home/movie"


const Home = ({ data, location }) => {
  return (
    <Layout
      pageMeta={{
        title: "Massoterapia e Riflessologia Plantare Relazionale",
        keywords: [
          "massoterapia",
          "Savona",
          "Psicosomatica",
          "riflessologia plantare",
          "Linfodrenaggio",
          "counseling",
        ],
        description:
          "Studio di Massoterapia e Riflessologia Plantare Relazionale a Savona",
        link: "/",
      }}
      location={location}>
      <Calendario/>
      <Banner/>
      <Promozioni/> 
      {/* <Movie/>    */}
        <div className={homeStyles.wrapper}>
        <div className={homeStyles.sky}></div>
        <div className={homeStyles.grass}></div>
        <Hero cssClass={homeStyles.hero}/>
        <Bio cssClass={homeStyles.bio}/>
        <Trattamento cssClass={homeStyles.trattamento}/>
        <Corso cssClass={homeStyles.corso}/> 
        <BlogVetrina cssClass={homeStyles.blog}/>
        <Aforisma cssClass={homeStyles.aforisma}/>
      </div>
    </Layout>
  )
}

export default Home