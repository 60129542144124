import React from "react"
import bannerStyles from "../../styles/common/banner.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import Markdown from "react-markdown"

const Banner = () => {

    const data = useStaticQuery(graphql`
    query BannerQuery {
      strapiBanner {
        pubblicato
        testo
        }
      }
    `)

  return (
    <div style={ data.strapiBanner.pubblicato ? {display:'block'} : {display:'none'} }>
      <Markdown className={bannerStyles.content}>{data.strapiBanner.testo}</Markdown>
    </div>
  )
}

export default Banner 