import React from "react"
import blogStyles from "../../styles/home/blog.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"


const BlogVetrina= ({cssClass}) => {
    const data = useStaticQuery(graphql`
    query BlogQuery {
      strapiVetrinaBlog {
        titolo
        post {
            slug
            titolo
            data(formatString: "DD MMMM, YYYY", locale: "it")
            foto {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
      }
    }
`)

  return (
    <div className={cssClass}>
      { data.strapiVetrinaBlog.titolo && <p className={blogStyles.titolo}>{data.strapiVetrinaBlog.titolo}</p>}
      {data.strapiVetrinaBlog.post &&  <div className={blogStyles.cardBorderWrap}>
        <Link className={blogStyles.card} to={`/blog/${data.strapiVetrinaBlog.post.slug}`}>
        <Img
          className={blogStyles.image}
          fluid={data.strapiVetrinaBlog.post.foto.childImageSharp.fluid}
          alt={`foto natura - ${data.strapiVetrinaBlog.post.foto.name}`}
        />
        <span className={blogStyles.title}>{data.strapiVetrinaBlog.post.titolo}</span>
        <span className={blogStyles.signature}>dp - {data.strapiVetrinaBlog.post.data}</span>
      </Link></div>}
    </div>

  )
}

export default BlogVetrina