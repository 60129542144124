import React from "react"
import blogStyles from "../../styles/home/blog.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"



const Corso = ({cssClass}) => {
    const data = useStaticQuery(graphql`
    query CorsoQuery {
      strapiVetrinaCorsi {
        titolo
        corso {
            slug
            titolo
            foto {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
      }
    }
`)

  return (
    <div className={cssClass}>
        {data.strapiVetrinaCorsi.titolo && <p className={blogStyles.titolo}>{data.strapiVetrinaCorsi.titolo}</p>}
        {data.strapiVetrinaCorsi.corso &&  <div className={blogStyles.cardBorderWrap}>
        <Link className={blogStyles.card} to={`/corsi/${data.strapiVetrinaCorsi.corso.slug}`}>
            <Img
                className={blogStyles.image}
                fluid={data.strapiVetrinaCorsi.corso.foto.childImageSharp.fluid}
                alt={`foto natura - ${data.strapiVetrinaCorsi.corso.foto.name}`}
            />
            <span className={blogStyles.title}>{data.strapiVetrinaCorsi.corso.titolo}</span>
        </Link></div>}
    </div>
  )
}

export default Corso