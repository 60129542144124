import React from "react"
import heroStyles from "../../styles/home/hero.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import Markdown from "react-markdown"
import Img from "gatsby-image"

const Hero = ({cssClass}) => {
    const data = useStaticQuery(graphql`
    query HeroQuery {
      strapiHero {
        titolo
        sottotitolo
        foto {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
        }
      }
    }
`)

  return (
    <div className={cssClass}>
      <h2 className={heroStyles.titolo}>{data.strapiHero.titolo}</h2>
      <h4 className={heroStyles.sottotitolo}>{data.strapiHero.sottotitolo}</h4>
      <Img
        className={heroStyles.foto}
        fluid={data.strapiHero.foto.childImageSharp.fluid}
        alt={data.strapiHero.foto.name}
      />
      {/* <Markdown className={heroStyles.content}>{data.strapiHero.testo}</Markdown> */}
    </div>
  )
}

export default Hero 