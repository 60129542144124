import React from "react"
import calendarioStyles from "../../styles/calendario/calendario.module.scss"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react"

import SwiperCore, { EffectCoverflow, Pagination } from "swiper"

// Import Swiper styles
import "swiper/swiper.scss"

// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination])

const MySwiper = () => {
  const data = useStaticQuery(graphql`
    query CalendarioAvventoQuery {
      allStrapiCalendarioAvvento(sort: { fields: createdAt, order: ASC }) {
        edges {
          node {
            strapiId
            data
            trattamento {
              slug
            }
            foto {
              ext
              name
              publicURL
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      allStrapiPromo {
        edges {
          node {
            codice
            fine
            inizio
            sconto
            strapiId
          }
        }
      }
    }
  `)

  let today_day = new Date()
    .toLocaleDateString("it-IT", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .slice(0, 2)
  const today_month = new Date()
    .toLocaleDateString("it-IT", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .slice(3, 5)

  const today_promo = data.allStrapiPromo.edges.filter(
    promo =>
      parseInt(promo.node.inizio.slice(8, 10).trim()) === parseInt(today_day)
  )

  const today_sconto =
    today_promo.length === 0 ? false : today_promo[0].node.sconto

  const slides = data.allStrapiCalendarioAvvento.edges.map(
    post =>
      today_month === "12" &&
      parseInt(post.node.data.slice(0, 2).trim()) <= parseInt(today_day) && (
        <div className={calendarioStyles.card} key={post.node.strapiId}>
          <span className={calendarioStyles.data}>{post.node.data}</span>
          <Img
            className={calendarioStyles.foto}
            fluid={post.node.foto.childImageSharp.fluid}
            alt={post.node.foto.name}
          />
          <Link
            to={`/trattamenti/${post.node.trattamento.slug}`}
            className={calendarioStyles.promoLink}
            style={
              parseInt(post.node.data.slice(0, 2).trim()) ===
              parseInt(today_day)
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <p className={calendarioStyles.promo}>{today_sconto}€</p>
            <div className={calendarioStyles.promoBg}></div>
          </Link>
        </div>
      )
  )

  return (
    <>
      <div className={calendarioStyles.desktop}>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"4"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          className="mySwiper"
        >
          {slides.map((slideContent, index) => (
            <SwiperSlide key={slideContent} virtualIndex={index}>
              {slideContent}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={calendarioStyles.ipad}>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"3"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          className="mySwiper"
        >
          {slides.map((slideContent, index) => (
            <SwiperSlide key={slideContent} virtualIndex={index}>
              {slideContent}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className={calendarioStyles.mobile}>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"2"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          className="mySwiper"
        >
          {slides.map((slideContent, index) => (
            <SwiperSlide key={slideContent} virtualIndex={index}>
              {slideContent}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}

export default MySwiper
