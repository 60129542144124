import React from "react"
import bioStyles from "../../styles/home/bio.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import Markdown from "react-markdown"
import Img from "gatsby-image"

const Bio = ({cssClass}) => {
    const data = useStaticQuery(graphql`
    query BioQuery {
      strapiBio {
        testo
        foto {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        collaborazioni {
          url
          logo  {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
`)

  return (
    <div className={cssClass}>
         <Img
        className={bioStyles.foto}
        fluid={data.strapiBio.foto.childImageSharp.fluid}
        alt={data.strapiBio.foto.name}
      />
      { data.strapiBio.testo && <Markdown className={bioStyles.testo}>{data.strapiBio.testo}</Markdown>}
      <div className={bioStyles.collaborazioni}>
      {data.strapiBio.collaborazioni.map(collab => (
        <a href={collab.url} target="_blank" rel="noopener noreferrer"> 
          <Img
          className={bioStyles.logo}
          fluid={collab.logo.childImageSharp.fluid}
          alt={collab.logo.name}
          />
        </a>))}
        </div>
    </div>
  )
}

export default Bio 