import React from "react"
import ReactPlayer from "react-player"

const Video = ({ url }) => (
  <div className="player-wrapper">
    <ReactPlayer
      url={url}
      loop
      playing
      controls
      className="react-player"
      width="100%"
      height="95.5%"
    />
  </div>
)

export default Video
